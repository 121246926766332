@import "../base-var";

/* ===== Variable and Mixin ===== */
$renk1: #f61f33;
$renk2: #505050;
$dark: #4a4a4a;

body {
	&.anasayfa {
		.site-head {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 60px;

		.container {
			padding: 0 20px;
		}
	}
}

.site-head {
	position: relative;
	z-index: 10;
	background: $renk2;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: calc((100vw - (#{map_get($container-max-widths, xl)})) / 2 + (#{$grid-gutter-width / 2}));
		height: 100%;
		background: #fff;
	}

	.container {
		position: relative;
	}

	.block {
		&.block-top {
			padding: 11px 0;
			border-bottom: 1px solid rgba(#fff, 0.1);
		}

		&.block-bottom {
			display: flex;
			justify-content: flex-end;
		}

		&.block-right {
			height: 110px;
		}

		@include media-breakpoint-down(sm) {
			&.block-bottom {
				display: block;
			}

			&.block-bottom {
				justify-content: flex-start;
			}
		}
	}

	.fix-element {
		position: absolute;
		right: 0;
		bottom: 2px;
	}

	.menu-btn {
		span.line {
			display: block;
			width: 35px;
			height: 2px;
			@include transition(all ease-out 0.2s);

			&:nth-child(2) {
				margin: 4px 0;
			}
		}

		&.active {
			span.line:first-child {
				transform: rotate(45deg) translateY(4px) translateX(4px);
			}

			span.line:nth-child(2) {
				opacity: 0;
			}

			span.line:last-child {
				transform: rotate(-45deg) translateY(-5px) translateX(5px);
			}
		}
	}

	.logo {
		display: flex;
		align-items: center;
		padding: 20px;
		margin: 0;
		width: 100%;
		height: 110px;
		background: #fff;

		a {
			display: block;

			img {
			}
		}
	}

	.menu {
		> nav {
			> ul {
				@include clearfix();
				margin: 0 -10px;

				> li {
					float: left;
					padding: 0 10px;
					position: relative;

					> a {
						font-size: 13px;
						line-height: 52px;
						font-weight: 700;
						color: #fff;
						display: block;
						text-transform: uppercase;
						padding: 0 10px;
						border-bottom: 5px solid transparent;
					}

					> ul {
						display: none;
						position: absolute;
						top: 60px;
						left: 10px;
						box-shadow: 0 0 15px 0 fade-out(#000, 0.92);
						min-width: 250px;
						padding-top: 10px;

						span {
							position: absolute;
							top: 0;
							left: 20px;
							border: 15px solid transparent;
							border-top-width: 0;
						}

						> li {
							padding: 0 20px;

							> a {
								font-size: 13px;
								color: #fff;
								text-transform: uppercase;
								padding: 20px 0;
								display: flex;
								align-items: center;

								&:before {
									@include fa-icon;
									font-family: $fa-font-name;
									font-weight: 900;
									content: fa-content($fa-var-chevron-right);
									font-size: 14px;
									color: #fff;
									display: block;
									float: left;
									width: 0;
									opacity: 0;
									@include transition(all ease 0.3s);
								}
							}
						}

						> li:hover {
							> a {
								&:before {
									width: 25px;
									opacity: 1;
								}
							}
						}

						> li + li {
							a {
								border-top: 1px solid fade-out(#fff, 0.9);
							}
						}
					}
				}
			}
		}
	}

	.social {
		float: right;
		padding: 15px 0;

		ul {
			@include clearfix();
			margin: 0 -5px;

			li {
				float: left;
				padding: 0 5px;
				display: none;

				a {
					display: block;
					width: 30px;
					height: 30px;
					border-radius: 30px;
					text-align: center;
					background: $gray-500;
					color: #fff;

					i {
						font-size: 14px;
						line-height: 30px;
					}

					span {
						display: none;
					}
				}
			}

			li:nth-child(1), li:nth-child(2) {
				display: block;
			}
		}
	}

	.contact-us {
		position: relative;
		font-size: 14px;
		line-height: 15px;
		text-align: right;
		letter-spacing: 1px;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.contact-us-icon {
			text-align: center;
			margin-right: 15px;

			i {
				width: 30px;
				height: 30px;
				border-radius: 30px;
				line-height: 32px;
				font-size: 12px;
			}
		}

		.contact-us-text {
			color: #fff;

			a {
				color: #fff;
			}

			.label-text {
				font-weight: 700;
				font-size: 12px;
			}

			.label-value {
				font-weight: 300;
				font-size: 18px;
				letter-spacing: -1px;
			}
		}
	}

	.search-box {
		float: right;
		padding: 8px 0;
		margin: 16px 0;

		form {
			position: relative;

			.box {
				border-radius: 44px;
				height: 44px;
				width: 44px;
			}

			.btn {
				width: 44px;
				height: 44px;
				border-radius: 44px;
				line-height: 44px;
				padding: 0;
				position: absolute;
				top: 0;
				right: 0;

				i {
					font-size: 17px;
				}
			}
		}
	}

	.language-box {
		position: absolute;
		top: 0;
		right: 0;
		padding: 20px 0;

		ul {
			@include clearfix();
			margin: 0 -5px;

			li {
				float: left;
				padding: 0 5px;

				a {
					display: block;
					overflow: hidden;
					font-size: 12px;
					font-family: $ff;
					font-weight: 400;
					color: #fff;
				}
			}

			li + li {
				border-left: 1px solid #fff;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.block {

			&.block-left {
				position: relative;
				z-index: 1;
				background: #fff;
				margin: 0 -20px;
				padding: 0 20px;
			}

			&.block-right {
				overflow-y: scroll;
				position: fixed;
				width: 280px;
				height: 100%;
				right: -280px;
				top: 0;
				background: #fff;
				padding-top: 60px;
				padding-left: 30px;
				padding-right: 30px;

				.container {
					width: 100%;
				}
			}
		}

		.fix-element {
			position: relative;
			bottom: 0;
		}

		.logo {
			margin: 0;
			height: auto;
			padding: 0;
			width: auto;

			a {
				display: block;
				height: auto;
				text-align: center;
				padding: 10px 0;

				img {
					height: 40px;
				}
			}
		}
		.menu {
			display: block;

			> nav {
				> ul {
					margin: 0;

					> li {
						padding: 0;
						border-bottom: 1px solid $hr-border-color;
						float: none;

						> a {
							padding: 5px 0 0 0;
							color: $dark;
						}

						> ul {
							display: block;
							background: none $i;
							position: relative;
							top: 0;
							left: 0;
							padding: 0;
							min-width: inherit;

							> li {
								> a {
								}
							}
						}
					}
				}
			}
		}
		.social {
			float: none;
		}
		.contact-us {
			position: relative;
			top: 0;
			padding: 15px;
			text-align: left;
			margin: 10px 0 0 0;
			float: none;
			border: none;
			border-bottom: 1px solid $hr-border-color;
			background: $renk2;
		}
		.language-box {
			position: relative;
		}
		.search-box {
			float: none;

			form {
				.box {
					width: 100%;
				}

				.btn {
				}
			}
		}
	}
}

.slider-box {
	position: relative;
	overflow: hidden;
	min-height: 100vh;

	.tp-caption {
		text-align: center $i;
		white-space: normal $i;
		line-height: 1.2 $i;

		.title {
			font-size: 60px;
			font-weight: 800;
			color: #fff;
			margin-bottom: 10px;
		}

		.sub-title {
			font-weight: 500;
			font-size: 40px;
			color: $dark;
			margin-bottom: 10px;
			white-space: normal;
		}

		.description {
			font-size: 20px;
			line-height: 26px;
			font-weight: 800;
			color: #fff;
		}

		.more-btn {
			margin-top: 40px;

			a {
				min-width: 160px;
				border-radius: 60px;
				padding: 0;
				line-height: 45px;
			}
		}
	}

	.tp-bullets {
		top: inherit $i;
		bottom: 100px $i;

		.tp-bullet {
			background: #fff;
			border-radius: 20px;
		}
	}

	@include media-breakpoint-down(sm) {
		.tp-caption {
			padding: 0 20px $i;

			.title {
				font-size: 32px;
			}

			.description {
				font-size: 17px;
				line-height: 20px;
			}
		}
		.tp-bullets {
			top: inherit $i;
			bottom: -60px $i;
		}
	}
}

.box-group {
	&.box-group-1 {
		padding: 0;
		margin-top: -145px;

		.row {
			margin: 0;

			div[class*="col-"] {
				padding: 0;
			}
		}

		.item {
			position: relative;

			.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}

			.image-box {
				position: relative;
				z-index: 0;

			}

			.text-box {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				color: #fff;

				.item-title {
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					position: absolute;
					top: 25px;
					left: 25px;
				}

				.item-description {
					font-size: 13px;
					font-weight: 400;
					position: absolute;
					bottom: 25px;
					left: 25px;
					padding-right: 70px;

					p {
						margin: 0;
					}
				}

				.item-btn {
					width: 32px;
					height: 32px;
					border-radius: 32px;
					border: 2px solid #fff;
					padding: 0;
					color: #fff;
					text-align: center;
					position: absolute;
					right: 25px;
					bottom: 25px;

					i {
						font-size: 17px;
						line-height: 28px;
					}
				}
			}

			&.item-1 {
				.bg {
					background-image: url(#{$img-path}box-1-bg.png);
				}
			}

			&.item-2 {
				.bg {
					background-image: url(#{$img-path}box-2-bg.png);
				}
			}

			&.item-3 {
				.bg {
					background-image: url(#{$img-path}box-3-bg.png);
				}
			}
		}
	}

	&.box-group-contact-us {
		position: relative;
		z-index: 1;
		margin-top: -80px;
		margin-bottom: 0;

		.or-box {
			span {
				display: none;
				width: 40px;
				height: 40px;
				border-radius: 40px;
				background: fade-out(#fff, 0.8);
				font-size: 12px;
				font-weight: 400;
				line-height: 40px;
				text-transform: capitalize;
				color: #fff;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				width: 1px;
				height: 100%;
				background: fade-out(#fff, 0.8);
			}
		}

		.box-group-inner {
			position: relative;
			color: #fff;
			margin: 0;

			.label-text {
				display: table-cell;
				vertical-align: middle;
				height: 80px;
				padding: 0 20px;

				> i {
					display: block;
					float: left;
					margin-right: 20px;
					font-size: 28px;
				}

				span.text {
					font-size: 14px;
					font-weight: 400;
					display: block;
					float: left;
					text-transform: capitalize;
					padding: 10px 0;
					max-width: 150px;
				}

				span.arrow {
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -10px;

					i {
						font-size: 18px;
						display: block;
					}
				}
			}

			.label-value {
				display: table-cell;
				vertical-align: middle;
				height: 80px;
				padding: 0 20px;
				width: 1%;
				text-align: center;

				a {
					font-size: 16px;
					font-weight: 700;
					color: #fff;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&.box-group-contact-us {
			.or-box {
				&:before, &:after {
					display: none;
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 0;
				}
			}

			.box-group-inner {
				padding: 20px 0;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: -20px;
					width: 50%;
					height: 1px;
					background: fade-out(#fff, 0.8);
				}

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: -20px;
					width: 50%;
					height: 1px;
					background: fade-out(#fff, 0.8);
				}

				div[class*="col-"] {
					&:nth-child(2) {
						padding-bottom: 40px;
					}

					&:nth-child(3) {
						padding-left: 10px;
						padding-top: 40px;
					}
				}

				.label-text {
					display: table;
					margin: 0 auto;
					padding: 0 20px;
					height: auto;

					span.arrow {
						display: none;
					}
				}

				.label-value {
					display: block;
					height: auto;
					padding: 0;
					width: auto;
					padding-top: 20px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		&.box-group-1 {
			margin-top: 0;

			.item {
				margin-bottom: 5px;

				.image-box {
					img {
						width: 100%;
					}
				}
			}
		}
	}

	@include media-breakpoint-only(md) {
		&.box-group-1 {
			.item {
				.image-box {
					img {
						width: auto;
					}
				}
			}
		}
		&.box-group-contact-us {
			.box-group-inner {
				.label-text {
					span.text {
						display: none;
					}

					span.arrow {
						display: none;
					}
				}
			}
		}
	}
}

.site-foot {
	position: relative;
	background: $renk2;
	padding: 20px 0;

	.foot-title {
		font-size: 12px;
		font-weight: 700;
		color: #fff;
		border-bottom: 1px solid fade-out(#fff, 0.8);
		padding: 20px 0;
		margin: 0 0 20px 0;
		text-transform: uppercase;
	}

	.copyright {
		a {
			display: block;
			border-bottom: 1px solid fade-out(#fff, 0.8);
			padding: 20px 0 30px 0;
			margin-bottom: 30px;

			img {
				filter: grayscale(1);
				opacity: 0.5;
			}
		}

		.text {
			font-size: 11px;
			font-weight: 400;
			color: fade-out(#fff, 0.3);
		}
	}

	.social {
		ul {
			@include clearfix();

			li {
				margin: 0 0 10px 0;

				a {
					display: block;
					@include clearfix();

					i {
						display: block;
						float: left;
						width: 26px;
						height: 26px;
						line-height: 26px;
						font-size: 14px;
						border-radius: 26px;
						text-align: center;
						background: fade-out(#fff, 0.8);
						color: $renk2;
						margin-right: 15px;
					}

					span {
						font-size: 11px;
						font-weight: 300;
						line-height: 26px;
						text-transform: uppercase;
						color: fade-out(#fff, 0.5);
						display: block;
						float: left;
					}
				}
			}
		}
	}

	.links {
		ul {
			margin-top: -10px;

			li {
				float: left;
				width: 50%;
				padding: 15px 0;

				span {
					display: block;
					float: left;
					width: 13px;
					height: 13px;
					background: $renk2;
					margin: 0 15px 0 0;
					border-radius: 10px;
					border: 4px solid;
				}

				a {
					font-size: 12px;
					font-weight: 400;
					color: fade-out(#fff, 0.5);
					display: block;
					text-transform: uppercase;
				}

				ul {
					display: none;
				}
			}
		}
	}

	&.fixed-footer {
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
	}
}