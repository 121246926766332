/* ===== Helper */
$img-path: '../images/';
$font-path: '../fonts/';
$i: !important;
$font-name: 'gilroy';
$font-sizes: xlight, light, regular, medium, bold, xbold;

/* ===== Sizes */
$box-group-padding: 140px;
$header-height: 160px;
$header-height-sm: 60px;

/* ===== Fonts */
$ff: 'sans-serif';
$fxl: $font-name + '-xlight';
$fl: $font-name + '-light';
$fr: $font-name + '-regular';
$fm: $font-name + '-medium';
$fb: $font-name + '-bold';
$fxb: $font-name + '-xbold';

/* ===== Colors */
$dark-blue-grey: #1f2752;
$deep-blue: #001489;
$bright-sky-blue-two: #00d0ff;
$turquoise-blue: #00b2d7;
$light-blue-grey: #e7f6f9;
$light-blue-grey-two: #dceff4;
$pale-grey-six: #f5f7f8;
$pale-grey-seven: #e7ebed;
$tiffany-blue: #99e0ef;
$dirty-purple: #493950;
$white-three: #f9f9f9;

$facebook-color: #3b5999;
$facebook-messenger-color: #0084ff;
$twitter-color: #55acee;
$instagram-color: #e4405f;
$google-plus-color: #dd4b39;
$whatsapp-color: #25D366;
$linkedin-color: #0077B5;
$gmail-color: #c71610;
$youtube-color: #cd201f;
$sms-color: #80e27e;
