@function strip-units($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}

	$base: 16;
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1rem;
}


@mixin getFonts($fontname) {
	$fontpath: "#{$font-path}#{$fontname}/";

	@each $size in $font-sizes {
		@font-face {
			font-family: '#{$fontname}-#{$size}';
			src: url('#{$fontpath}#{$fontname}-#{$size}.woff2') format('woff2'),
			url('#{$fontpath}#{$fontname}-#{$size}.woff') format('woff'),
			url('#{$fontpath}#{$fontname}-#{$size}.svg') format('svg');
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
		}

		.text-#{$fontname}-#{$size} {
			font-family: '#{$fontname}-#{$size}';
		}
	}
}

@mixin article() {
	@extend .clearfix;
	.paragraph {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		color: rgba($body-color, 0.7);
	}

	h1 {
	}

	p {
		margin: 0 0 $paragraph-margin-bottom 0;
		@extend .paragraph;
	}

	b {
		font-weight: 400;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			@extend .paragraph;
			margin-bottom: 10px;

			&:before {
				content: '';
				display: block;
				float: left;
				width: 8px;
				height: 8px;
				background: #fff;
				margin: 8px 15px 0 0;
				border-radius: 10px;
				border: 2px solid $primary;
			}
		}
	}

	img {
		margin-bottom: 40px;
		max-width: 100%;

		&[style*=left] {
			margin-right: 50px;
		}

		&[style*=right] {
			margin-left: 50px;
		}
	}

	@include media-breakpoint-down(md) {
		img {
			width: 100%;
		}
	}
}

@mixin shortText($font-size, $line-height, $rows) {
	height: $font-size * $line-height * $rows;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: $rows;
	-webkit-box-orient: vertical;
}

@mixin centerImage($width: '', $height: '') {
	@if $width {
		width: $width;
	}
	@if $height {
		height: $height;
	}
	object-fit: cover;
	object-position: center;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

@mixin textBox() {
	padding: 15px 0;
	.item-title {
		font-size: 18px;
		font-weight: 500;
		color: $body-color;
		padding: 0;
		letter-spacing: -1px;

		span {
			font-size: 14px;
			font-weight: 400;
			color: $body-color;
			display: block;
			letter-spacing: 0;
			margin-top: 3px;
		}
	}
	.item-text {
		font-size: 14px;
		font-weight: 400;
		color: $body-color;
		margin-top: 10px;
	}
	.item-social {
		display: table;
		margin: 15px auto 0;

		ul {
			@extend .clearfix;
			margin: 0 -2px;

			li {
				float: left;
				padding: 0 2px;

				a {
					display: block;
					width: 30px;
					height: 30px;
					border-radius: 30px;
					background: $input-border-color;
					color: #fff;
					text-align: center;

					i {
						font-size: 14px;
						line-height: 30px;
					}
				}
			}
		}
	}
	.item-date {
		font-size: 16px;
		font-weight: 300;
		color: $body-color;
		letter-spacing: -1px;
		border: 1px solid $hr-border-color;
		padding: 20px;
		display: inline-block;
		margin-bottom: 30px;

		i {
			display: block;
			float: right;
			margin: 0 0 0 15px;
			font-size: 19px;
			color: $gray-500;
		}
	}
	.item-btn {
		min-width: 140px;
		margin-top: 20px;
	}

	&.text-border {
		border: 1px solid $hr-border-color;
		margin-top: -1px;
	}

	@include media-breakpoint-down(sm) {
		.item-date {
			padding: 10px 20px;
			display: block;
		}
		.item-btn {
			width: 100%;
		}
	}
}